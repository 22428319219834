import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Link from "components/common/Link";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { alpha, makeStyles } from "@material-ui/core/styles";

import CmtImage from "@jumbo-coremat/CmtImage";
import IntlMessages from "@jumbo-theme/utils/IntlMessages";
import { AuthMethods } from "@jumbo/services/auth";
import { CurrentAuthMethod } from "@jumbo-theme/constants/AppConstants";
import ContentLoader from "@jumbo-theme/components/ContentLoader";
import AuthWrapper from "@jumbo-theme/components/Common/authComponents/AuthWrapper";

import Logo from "../../AppLayout/partials/Logo";
import { useCurrentUser } from "components/App/CurrentUserContext";
import useCurrentUserClient from "hooks/useCurrentUserClient";

const propTypes = {
  defaultEmail: PropTypes.string,
  isToggleVisible: PropTypes.bool,
  method: PropTypes.string,
  onSuccess: PropTypes.func,
  urlParams: PropTypes.object,
  variant: PropTypes.oneOf(["default", "standard"]),
  wrapperVariant: PropTypes.oneOf(["default", "bgColor"])
};

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formControlLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }
}));

export default function SignIn({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
  defaultEmail,
  onSuccess,
  urlParams,
  isToggleVisible = false
}) {
  const [email, setEmail] = useState(defaultEmail ?? "");
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { setCurrentUser } = useCurrentUser();
  const { refetch: onRefreshCurrentUserClient } = useCurrentUserClient()();

  const handleSuccess = (user) => {
    if (onSuccess) {
      onSuccess(user);
    }

    setCurrentUser(user);
    onRefreshCurrentUserClient();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    dispatch(
      AuthMethods[method].onLogin(
        { email, password, rememberMe },
        { onSuccess: handleSuccess }
      )
    );

    // extra guard to prevent a FormPost
    return false;
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src="/images/auth/login-img.png" />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <Logo ml={2} color="white" />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="appModule.signIn" />
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <FormControlLabel
              checked={rememberMe}
              className={classes.formControlLabelRoot}
              control={<Checkbox name="checkedA" />}
              label={<IntlMessages id="appModule.rememberMe" />}
              onChange={(event) => setRememberMe(event.target.checked)}
            />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <Link
                href={{
                  pathname: "/account/forgot-password",
                  query: urlParams
                }}
              >
                <IntlMessages id="appModule.forgotPassword" />
              </Link>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button type="submit" variant="contained" color="primary">
              <IntlMessages id="appModule.signIn" />
            </Button>

            {isToggleVisible && (
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <Link
                  href={{
                    pathname: "/account/register",
                    query: urlParams
                  }}
                >
                  <IntlMessages id="signIn.signUp" />
                </Link>
              </Box>
            )}
          </Box>
        </form>

        {dispatch(AuthMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
}
SignIn.propTypes = propTypes;
