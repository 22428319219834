import React from "react";
import { useRouter } from "next/router";
import Head from "next/head";

import SignIn from "@theme-overrides/components/Common/authComponents/SignIn";

export default function LoginPage() {
  const router = useRouter();
  const { destination } = router.query;

  const handleSuccess = () => {
    const url = new URL(destination || "/", window.location.href); // it's ok
    url.searchParams.set("auth", "login");
    router.replace(url);
  };

  return (
    <>
      <Head>
        <title>
          Log In | Portfolio Catapult | Data-Driven Investment Portfolios
        </title>
      </Head>
      <SignIn
        isToggleVisible={false} // keep the site closed for now
        onSuccess={handleSuccess}
        urlParams={router.query}
        variant="standard"
        wrapperVariant="default"
      />
    </>
  );
}

// use getStaticProps here?
LoginPage.isPublic = true;
LoginPage.isFullScreen = true;
